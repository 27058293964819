document.addEventListener("turbolinks:load", function() {
    if ($("body").data("user_role") == "super_admin" || $("body").data("user_role") == "admin") {
        function getSolde() {
            fetch(`/settings/solde/`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        if (parseFloat(data.solde) != NaN) {
                            if ( parseFloat(data.solde) >= 50) {
                                $(".current_solde").text(`Solde SMS: ${data.solde} TND`)
                            }
                            else{
                                $(".current_solde").html(`Solde SMS: <span class='badge bg-danger' style='font-size: 12px;'>${data.solde} TND</span>`)
                            }

                        }
                        else {
                            $(".current_solde").text('-')
                        }
                    })
                }
            })
        }

        getSolde()

}

    if($("body").data("controller") == "settings") {
        const modal_service = new bootstrap.Modal(document.getElementById("modal-service"), {backdrop: 'static', keyboard: false});
        let service_id;
        let method = 'create'

        table = $('#table-equipe').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']]
        });

        table_services = $('#table-services').DataTable({
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            "columnDefs": [
                {"className": "dt-center", "targets": [1, 2]}
            ],
            'order' : [[0,'desc']]
        });

        $('#btn-new_service').on("click", function(e) {
            modal_service.show()
        })

        $(document).on("click", "#table-services .btn-edit-service", function(e) {
            service_id = $(e.currentTarget).parent().parent().data('element_id')
            method = 'edit'

            getService()

            modal_service.show()
        })
        
        document.getElementById("modal-service").addEventListener('hidden.bs.modal', function (event) {
            $(".service-btn_delete_image").remove()
            $("#service-name").val("")
            $("#service-slug").val("")
            $("#service-duration").val('1')
            $("#service-min_time_booking").val('')
            $("#service-status").attr('checked', false)
            $("#service-description").val('')
            $(".img img").remove()
            $("#service-image").val("")
            
            $('input, button, select, textarea').attr('disabled', false)

            method = 'create'
        })

        $('#form-service').on('submit', function(event) {
            const forms = document.querySelectorAll('#modal-service .needs-validation')
            let isValid = true
            
            event.preventDefault()
            let image = event.target.uploadFile.files[0]

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $('input, button, select, textarea').attr('disabled', true)

                if (method == 'create')
                    createService(image)
                else if(method == 'edit')
                    updateService(image)
            }
        })

        $("#table-services").on('click', ".btn-delete-service", function(e) {
            deleteService($(e.currentTarget).parent().parent().data('element_id'))
        })

        $("#btn-delete-background").on("click", function() {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer l'arrière-paln?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/settings/background`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                
                              window.location.reload()
                            })
                        }
                    })
                }
            }).then((result) => {})
        })

        $("#form-logo").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-logo .needs-validation')
            let isValid = true

            event.preventDefault()

            let image = event.target.uploadLogo.files[0]

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $('input, button, select, textarea').attr('disabled', true)
                $("#btn-save-logo .spinner-border").removeClass("d-none")
                
                let formData = new FormData();
                formData.append('image', image);

                fetch(`/settings/logo`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {

                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-logo .spinner-border").addClass("d-none")

                            if ($("#logo-thumb").length != 0) {
                                $("#logo-thumb").attr("src", data.image.url)
                            }
                            else {
                                $(".no_logo").remove()
                                $(".img_logo").append(`<img src="${data.image.url}" class="p-3" id="logo-thumb" width="150" >`)
                            }
                        })
                    }
                })
            }
        })

        $("#form-contact").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-contact .needs-validation')
            let isValid = true

            $(".alert-error-contact").addClass("d-none")

            event.preventDefault()

            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $('input, button, select, textarea').attr('disabled', true)
                $("#btn-save-contact .spinner-border").removeClass("d-none")
                
                let formData = new FormData();
                formData.append('email', $("#contact-email").val());
                formData.append('phone', $("#contact-phone").val());

                fetch(`/settings/contacts`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-contact .spinner-border").addClass("d-none")

                            $(".alert-contact").removeClass("d-none")
                            $(".alert-contact").text("Enregistré!")

                        })
                    }
                })
            }
        })


        $("#form-background").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-background .needs-validation')
            let isValid = true

            $(".alert-error-background").addClass("d-none")

            event.preventDefault()

            let image = event.target.uploadBackground.files[0]

            if(image.size > 500000) {
                $(".alert-error-background").removeClass("d-none")
                $(".alert-error-background").text("La taille du fichier doit être inférieure à 500 ko")

                this.value = "";
                isValid = false;
            }
            
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {

                $('input, button, select, textarea').attr('disabled', true)
                $("#btn-save-logo .spinner-border").removeClass("d-none")
                
                let formData = new FormData();
                formData.append('image', image);

                fetch(`/settings/background`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-logo .spinner-border").addClass("d-none")

                            if ($("#background-thumb").length != 0) {
                                $("#background-thumb").attr("src", data.image.url)
                            }
                            else {
                                $(".no_background").remove()
                                $(".img_background").append(`<img src="${data.image.url}" class="p-3" id="background-thumb" width="150" >`)
                            }

                            window.location.reload()
                        })
                    }
                })
            }
        })

        $("#form-sms").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-sms .needs-validation')
            let isValid = true

            $(".alert-sms").addClass('d-none')

            event.preventDefault()

            let formData = new FormData();
                
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }
                else {
                    formData.append(`sms[${form.name}[name]]`, form.value);
                    formData.append(`sms[${form.name}[active]]`, $(`#${form.name}_active`).prop("checked"));
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {
                $("#btn-save-sms .spinner-border").removeClass("d-none")

                $('input, button, select, textarea').attr('disabled', true)

                fetch(`/settings/sms`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-sms .spinner-border").addClass("d-none")

                            $(".alert-sms").removeClass('d-none')
                            $(".alert-sms").text("Enregistré!")
                        })
                    }
                })
            }
        })
        
        $("#form-sms_key").on("submit", function(event) {
            const forms = document.querySelectorAll('#form-sms_key .needs-validation')
            let isValid = true

            $(".alert-sms").addClass('d-none')

            event.preventDefault()

            let formData = new FormData();
                
            Array.prototype.slice.call(forms).forEach(function (form) {
                if (form.value == "") {
                    form.classList.add('is-invalid')
                    isValid = false
                    event.preventDefault()
                    event.stopPropagation()
                }
                else {
                    formData.append(`sms[${form.name}]`, form.value);
                    formData.append(`sms[sms_deactivate]`, $("#sms_deactivate").prop("checked"));
                }

                form.classList.add('was-validated')
            }, false)

            if (isValid) {
                $("#btn-save-sms .spinner-border").removeClass("d-none")

                $('input, button, select, textarea').attr('disabled', true)

                fetch(`/settings/sms_key`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $('input, button, select, textarea').attr('disabled', false)
                            $("#btn-save-sms_key .spinner-border").addClass("d-none")

                            $(".alert-sms_key").removeClass('d-none')
                            $(".alert-sms_key").text("Enregistré!")
                        })
                    }
                })
            }
        })

        $(document).on("click", "#service-delete_image", function() {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer l'image?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/services/delete-image/${service_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                $(".service-image_thumb").remove()
                                $(".service-btn_delete_image").remove()
                            })
                        }
                    })
                }
            }).then((result) => {})
        })

        $(document).on("click", "#btn_delete_logo", function() {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer le logo?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/settings/delete-logo`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                $("#logo-thumb").remove()
                                $(".btn_delete_logo").remove()

                                $(".img_logo").append("<div class='no_logo mt-5'>Pas d'image</div>")
                            })
                        }
                    })
                }
            }).then((result) => {})
        })

        // *** FUNCTIONS
        function getService() {
            fetch(`/services/${service_id}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $("#service-name").val(data.name)
                        $("#service-slug").val(data.slug)
                        $("#service-duration").val(data.duration)
                        $("#service-min_time_booking").val(data.min_time_prior_booking)
                        $("#service-status").prop('checked', data.active)
                        $("#service-description").val(data.description)
                        if(data.accept_auto_new_reservation == true)
                            $("#service-appointment_acceptation_yes").prop("checked", true)
                        else {
                            $("#service-appointment_acceptation_no").prop("checked", true)
                        }
                        if (data.image.url != null) {
                            $(".img").append(`<img src='${data.image.url}' class='service-image_thumb m-2' width='128'> `)
                            $(".img").append(`<div class='service-btn_delete_image'><a href="javascript:void(0)" id="service-delete_image">Supprimer l'image</a></div>`)
                        }
                        
                        if (data.slug != "entretien-périodique" && data.slug != "diagnostic-mécanique" && data.slug != "carrosserie") {
                            $("#service-slug").attr("disabled", false)
                        }
                        else {
                            $("#service-slug").attr("disabled", true)
                        }
                    })
                }
            })
        }

        function createService(image) {
            let formData = new FormData();
            formData.append('name', $("#service-name").val());
            formData.append('slug', $("#service-slug").val());
            formData.append('duration', $("#service-duration option:selected").val());
            formData.append('min_time_prior_booking', $("#service-min_time_booking option:selected").val());
            formData.append('accept_auto_new_reservation', $(`input[name='service-appointment_acceptation']:checked`).attr("data-accept"));
            formData.append('active', $("#service-status").prop('checked'));
            formData.append('description', $("#service-description").val());

            if (image != null)
                formData.append('image', image);

            fetch(`/services`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        let badge = data.active ? 'success' : 'warning'
                        let active = `<span class='badge bg-${badge}'>${data.active}</span>`

                        $('input, button').attr('disabled', false)
                        $("#service-name").val('')
                        $("#service-slug").val('')
                        $("#service-duration").val(data.duration)
                        $("#service-min_time_booking").val(data.min_time_prior_booking)
                        $("#service-status").prop('checked', false)
                        $("#service-description").val("")
                        
                        let newRow = table_services.row.add([
                            data.name,
                            //data.duration + "h",
                            active,
                            `<a href="javascript:" class="btn-edit-service link-primary" title="Modifier"><span class="material-symbols-outlined">edit</span></a>
                            <a href="javascript:" class="btn-delete-service link-danger" title="Supprimer"><span class="material-symbols-outlined">delete</span></a>`
                        ] ).draw( false ).node();
    
                        $(newRow).attr("data-element_id", data.id);

                        //deleteService(service_id)

                        modal_service.hide()
                    })
                }
            })
        }

        function updateService(image) {
            let formData = new FormData();
            formData.append('name', $("#service-name").val());
            formData.append('slug', $("#service-slug").val());
            formData.append('duration', $("#service-duration option:selected").val());
            formData.append('min_time_prior_booking', $("#service-min_time_booking option:selected").val());
            formData.append('accept_auto_new_reservation', $(`input[name='service-appointment_acceptation']:checked`).attr("data-accept"));
            formData.append('active', $("#service-status").prop('checked'));
            formData.append('description', $("#service-description").val());

            if (image != null)
                formData.append('image', image);

            fetch(`/services/${service_id}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        let badge = data.active ? 'success' : 'warning'
                        let active = `<span class='badge bg-${badge}'>${data.active}</span>`

                        $(`#table-services tr[data-element_id='${data.id}'] td:nth-child(1)`).text(data.name);
                       // $(`#table-services tr[data-element_id='${data.id}'] td:nth-child(2)`).text(data.duration  + "h");
                        $(`#table-services tr[data-element_id='${data.id}'] td:nth-child(2)`).html(active);

                        //deleteService(service_id)

                        modal_service.hide()
                    })
                }
            })
        }

        function deleteService(service_id) {
            Swal.fire({
                title: 'Supprimer?',
                text: "Voulez-vous vraiment supprimer ce service?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/services/${service_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            response.json().then(function(data) {
                                
                                $(`tr[data-element_id='${data.id}']`).remove()

                                modal_service.hide()
                            })
                        }
                    })
                }
            }).then((result) => {})
        }
    }
})