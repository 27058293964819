

(function ($) {
	var weekdays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
	var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];


	function AvailabilityCalendar(container, bookedDates, weekends) {
		this.date = new Date();
		this.date.setDate(1);

		this.weekends = weekends;
		this.container = container;
		this.bookedDates = bookedDates;

		this.createCalendar();
		this.renderMonth();
	}


	AvailabilityCalendar.prototype = {
		/**
		 * Setup methods
		 */
		__createToolbar: function () {
			var $toolbar = $('<div></div>').appendTo(this.container);
			$toolbar.addClass('availability-calendar-toolbar');

			this.$monthLabel = $('<span></span>').appendTo($toolbar);
			var $inputContainer = $('<span></span>').appendTo($toolbar);

			$inputContainer.append('<input type="button" title="Ce mois-ci" value="Ce mois-ci">');
			$inputContainer.append('<input type="button" title="Le mois précédent" value="&#10094;">');
			$inputContainer.append('<input type="button" title="Le mois suivant" value="&#10095;">');

			var $inputs = $inputContainer.children('input');
			var self = this;

			$inputs.eq(0).on('click', function () {
				self.date = new Date();
				self.date.setDate(1);
				self.renderMonth();
			});

			$inputs.eq(1).on('click', function () {
				self.date.setMonth(self.date.getMonth() - 1);
				self.renderMonth();
			});

			$inputs.eq(2).on('click', function () {
				self.date.setMonth(self.date.getMonth() + 1);
				self.renderMonth();
			});
		},
		__createTable: function () {
			var $table = $('<table></table>').appendTo(this.container);
			$table.addClass('availability-calendar');

			//console.log(this.weekends)
			// Weekday headers
			var $tr = $('<tr></tr>').appendTo($table);

			weekdays.forEach(function (day) {
				
				if (day != "Samedi" && day != "Dimanche")
					$('<th></th>').html(day).appendTo($tr);
			});

			// Day cells
			for (var i = 0; i < 6; ++i) {
				if (i != 0 && i != 6) {
					$tr = $('<tr></tr>').appendTo($table);
					$tr.append('<td></td><td></td><td></td><td></td><td></td><td></td><td></td>');
				
					// $tr = $('<tr></tr>').appendTo($table);
					// $tr.append('<td></td><td></td><td></td><td></td><td></td><td></td><td></td>');
				}
			}

			this.$cells = $table.find('td');
		},
		createCalendar: function () {
			this.__createToolbar();
			this.__createTable();
		},


		/**
		 * Month rendering methods
		 */
		__addPreviousMonthDays: function (date, cellIndexes, dates) {
			var firstWeekdayOfMonth = date.getDay() - 1;
			if (firstWeekdayOfMonth < 0) firstWeekdayOfMonth = 6;

			if (firstWeekdayOfMonth > 0) {
				date.setDate(0);
				var numDays = date.getDate();

				for (var i = numDays - firstWeekdayOfMonth + 1; i <= numDays; ++i) {
					this.$cells.eq(dates.length).html(i).addClass('ex-month');

					date.setDate(i);
					var dateInt = date.valueOf();

					cellIndexes[dateInt] = dates.length;
					dates.push(dateInt);
				}
				
			}
		},
		__addThisMonthDays: function (date, year, month, cellIndexes, dates) {
			date.setFullYear(year, month + 1, 0); // Need to reset year
			var numDays = date.getDate();

			for (var i = 1; i <= numDays; ++i) {
				date.setDate(i);

				$(this.$cells.eq(dates.length)).attr("data-date", moment(date).format('YYYY-MM-DD'))
				// remove weekends
				if (this.weekends.includes(moment(date).format('YYYY-MM-DD')))
					$(this.$cells.eq(dates.length)).addClass("d-none")

				this.$cells.eq(dates.length).html(i + ` <a href='javascript:void(0)' class='create_new_appoint' data-bs-toggle='modal' data-date='${moment(date).format('YYYY-MM-DD')}' data-bs-target='#modal-new_appointment' class='new-appointment' title='Ajouter un rendez-vous'><i class="fa-solid fa-plus" aria-hidden="true"></i></a>` + ` <a href='javascript:void(0)' class='show_list_${moment(date).format('YYYY-MM-DD')}' data-date='${moment(date).format('YYYY-MM-DD')}'><i class="fa-solid fa-eye" aria-hidden="true"></i></a>`);

				$(`.show_list_${moment(date).format('YYYY-MM-DD')}`).on("click", function(e) {
					let date = $(this).attr("data-date")
					$("#myCalendar").addClass("d-none")
					$("#myCalendarList").removeClass("d-none")

					show_calendar_list(date)
				})
				
				var dateInt = date.valueOf();
				
				cellIndexes[dateInt] = dates.length;
				dates.push(dateInt);
	
				
				$(document).on("click", ".create_new_appoint", function() {
					$("#new-appoint-date").val($(this).attr("data-date"))
				})
				
			}
			
		},
		__addNextMonthDays: function (date, month, cellIndexes, dates) {
			
			if (dates.length < 42) {
				date.setMonth(month + 1, 1);
				var remainingDays = 42 - dates.length;
			
				for (var i = 1; i <= remainingDays; ++i) {
					
					this.$cells.eq(dates.length).html(i).addClass('ex-month');
					$(this.$cells.eq(dates.length)).attr("data-date", moment(date).add(1, 'days').format('YYYY-MM-DD'))
					
					date.setDate(i);
					var dateInt = date.valueOf();

					cellIndexes[dateInt] = dates.length;
					dates.push(dateInt);

					if (this.weekends.includes(moment(date).add(1, 'days').format('YYYY-MM-DD')) || this.weekends.includes(moment(date).format('YYYY-MM-DD')))
						$(this.$cells.eq(dates.length)).addClass("d-none")
					// console.log(moment(date).format('YYYY-MM-DD'))
					
				}

				
			}

			for (var i = 1; i <= remainingDays; ++i) {
				

			}
		},
		__addEvents: function (cellIndexes, dates, data) {
			const modal_appointment = new bootstrap.Modal(document.getElementById("modal-see_appointment"), {backdrop: 'static', keyboard: false});
			var firstDate = dates[0];
			var lastDate = dates[dates.length - 1];
			var self = this;
			
			this.bookedDates.forEach(function (date) {
				
	
				if (date.start <= lastDate && date.end >= firstDate) {
					var startIndex = cellIndexes[date.start];
					var endIndex = cellIndexes[date.end];

					if (startIndex !== undefined) {
						self.$cells.eq(startIndex).addClass('').append(`<div class="${moment(date.start).format('YYYY-MM-DD')} text-center "></div>`);
						++startIndex;
					}
					else {
						startIndex = cellIndexes[firstDate];
					}

					if (endIndex !== undefined) {
						self.$cells.eq(endIndex).addClass('').append('<div class="last"></div>');
						--endIndex;
					}
					else {
						endIndex = cellIndexes[lastDate];
					}

					self.$cells.slice(startIndex, endIndex + 1).addClass('unavailable').append('<div></div>');
				}
			});

			fetch(`/appointments/get-appointments/${$("#selected_service").val()}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
				headers: {
					'Cache-Control': 'no-cache'
				  }
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {

						let index = 1;
						let rdv;
						
						for(appointment of data) {
							$(`.${appointment.date}`).html("")
						}

                        for(appointment of data) {
							
							if (rdv != appointment.date ) {
								index = 1
								rdv = appointment.date
							}
							else {
								index += 1
							}

							let color
							let new_appointment = ''

							if (appointment.service.slug == 'entretien-périodique')
								color = 'primary'
							else if (appointment.service.slug == 'diagnostic-mécanique')
								color = 'success'
							else if (appointment.service.slug == 'carrosserie')
								color = 'secondary'

							if (appointment.new) {
								new_appointment = `<span class="indicator position-absolute start-0 start-100 translate-middle bg-danger border border-light rounded-circle"><span class="visually-hidden"></span></span>`
							}

							$(`.${appointment.date}`).append(`<a href='javascript:' class='appointment-${appointment.id} position-relative' data-appointment_id='${appointment.id}'>
								<span class="badge text-bg-${color}" style='border: 1px solid white; text-wrap: balance;'> 
									<span class='car_number_calendar'>${appointment.car.car_number}</span> ${appointment.time} - ${moment(new Date(`2024-08-27 ${appointment.time}`)).add(appointment.duration, "minutes").format("k:mm")}<br>
								</span>
								${new_appointment}
								<div class='state' style='display: none;'>${appointment.decorate_calendar_state}</div>
								
								</a>`)
							$(`.${appointment.date}`).attr('data-appointment_id', appointment.id)

							$(`.appointment-${appointment.id}`).on("click", function(e) {
								fetch(`/appointments/${$(e.currentTarget).data('appointment_id')}`, {
									method: 'GET',
									mode: 'cors',
									cache: 'default',
								}).then(function(response) {
									if (response.ok) {
										response.json().then(function(data) {
											
											$(`.appointment-${$(e.currentTarget).data('appointment_id')} .indicator`).remove()
											$('#see_appointment_id').val(data.id)
											$("#see_service").val(`${data.service.name} (${data.workshop_service.duration} ${Pluralize( 'heure', data.workshop_service.duration )})`)
											$("#see_date").val(moment(data.date).locale("fr").format('dddd DD MMM YYYY'))
											$("#see_periode").val(`${data.time} à ${parseInt(data.time.replace('h:00','')) + data.workshop_service.duration}h:00`);
											$("#see_full_name").val(data.user.full_name)
											$("#see_phone").val(data.phone)
											$("#see_email").val(data.email);
											$("#see_car_number").val(`${data.car.model} (${data.car.car_number})`);
											$("#see_km").val(data.km);
											$("#see_notes").val(data.notes);
											$("#see_description").val(data.description);
											$("#see_note_intern").val(data.note_intern);
											$("#see_state").val(data.state);
											$('#see_note_client').val(data.note_client)

											if (data.state == "completed")  {
                                                $(".invoice_container").removeClass("d-none")
                                                $("#see_invoice").val(data.invoice)
                                            }
                                            else {
                                                $(".invoice_container").addClass("d-none")
                                                $("#see_invoice").val("")
                                            }
											
											if (data.service.slug == 'carrosserie') {
												$(".carrosserie").removeClass('visually-hidden')
											}
											else {
												$(".carrosserie").addClass('visually-hidden')
											}

											modal_appointment.show()
										})
									}
								})
							})
						}
                    })
                }
            })
		},
		renderMonth: function () {
			var cellIndexes = {};
			var dates = [];

			var year = this.date.getFullYear();
			var month = this.date.getMonth();
			var date = new Date(year, month, 1);
		
			this.$monthLabel.html(months[month] + ' ' + year);
			this.$cells.removeClass('ex-month');
			this.$cells.filter('.unavailable').removeClass('unavailable').children().remove();

			this.__addPreviousMonthDays(date, cellIndexes, dates);
			this.__addThisMonthDays(date, year, month, cellIndexes, dates);
			this.__addNextMonthDays(date, month, cellIndexes, dates);

			this.__addEvents(cellIndexes, dates);
		}
	};


	$.fn.availabilityCalendar = function (bookedDates) {
		var dates = [];

		bookedDates.appointments.forEach(function (date) {
			var start = new Date(date.start);
			var end = new Date(date.end);

			start.setHours(0, 0, 0, 0);
			end.setHours(0, 0, 0, 0);

			start = start.valueOf();
			end = end.valueOf();

			if (start <= end) {
				dates.push({
					start: start,
					end: end
				});
			}
		});

		this.each(function () {
			new AvailabilityCalendar(this, dates, bookedDates.weekends);
		});

		return this;
	};


	
	function show_calendar_list(date) {

		let service_id = $("#selected_service").val()
		if (service_id == "") {
			service_id='all'
		}

		$("#selected_date").text("")
		$("#list_appointments").html("<img src='/assets/loader2.gif' width='48'>")
		
		fetch(`/appointments/get-day-appointments/${date}/${service_id}`, {
			method: 'GET',
			mode: 'cors',
			cache: 'default',
		}).then(function(response) {
			if (response.ok) {
				response.json().then(function(data) {

					const modal_appointment = new bootstrap.Modal(document.getElementById("modal-see_appointment"), {backdrop: 'static', keyboard: false});

					$("#list_appointments").html("")

					$("#selected_date").text(moment(date).format("dddd LL"))

					if (data.appointments.length == 0) {
						$("#list_appointments").append(`
							<div class='text-center mt-3 mb-3'>Aucun rendez vous disponible!</div>
						`)
					}

				
					data.dates.forEach(function(date) {
						//let to = moment(`2024-08-27 ${}`)
						$("#list_appointments").append(`<table class='table_${date[0]}_${date[1]} mb-3 w-100'>
								<tbody>
								<tr style='background: #f7f7f7;'>
									<td class='p-3 text-center'>
										<b>${date[1]} à ${ moment(new Date(`2024-08-27 ${date[1]}`)).add(date[2], "minutes").format("k:mm")}
									</td>
								</tr>
								<tr>
									<td class='p-2'>
										<div class='date_${date[0]}_${date[1].replace(":", "h")} mb-3'>
										</div>
									</td>
								</tr>
								</tbody>
							</table>
						`)
					})

					data.appointments.forEach(function(appointments) {
						appointments.forEach(function(appointment) {
							let time =  appointment.time

							let state = ""
							
							if (appointment.state == "completed")
								state = "<span class='badge text-bg-success'>Fini</span>"
							if (appointment.state == "accepted")
								state = "<span class='badge text-bg-primary'>Accepté</span>"
							if (appointment.state == "in_progress")
								state = "<span class='badge text-bg-info'>En cours d'entretien</span>"
							else if (appointment.state == "missed") {
								state = "<span class='badge text-bg-danger'>Client ne s'est pas présenté</span>"
							}

							$(`.date_${appointment.date}_${time.replace(":", "h")}`).append(`<div>
								- <a href="javascript:void(0)" class='appointment-${appointment.id} rdv' data-appointment_id='${appointment.id}'> ${state} ${appointment.service.name} (${appointment.user.full_name} - <span style='color:#008dbb'>${appointment.car.car_number}</span>)</a>
							</div>`)

							$(`.appointment-${appointment.id}`).on("click", function(e) {
								fetch(`/appointments/${$(e.currentTarget).data('appointment_id')}`, {
									method: 'GET',
									mode: 'cors',
									cache: 'default',
								}).then(function(response) {
									if (response.ok) {
										response.json().then(function(data) {

											$(`.appointment-${$(e.currentTarget).data('appointment_id')} .indicator`).remove()
											$('#see_appointment_id').val(data.id)
											$("#see_service").val(`${data.service.name} (${data.duration} ${Pluralize( 'minute', data.duration )})`)
											$("#see_date").val(moment(data.date).locale("fr").format('dddd DD MMM YYYY'))
											$("#see_periode").val(`${data.time} à ${ moment(new Date(`2024-08-27 ${data.time}`)).add(data.duration, "minutes").format("k:mm")}`);
											$("#see_full_name").val(data.user.full_name)
											$("#see_phone").val(data.phone)
											$("#see_email").val(data.email);
											$("#see_car_number").val(`${data.car.model} (${data.car.car_number})`);
											$("#see_km").val(data.km);
											$("#see_notes").val(data.notes);
											$("#see_description").val(data.description);
											$("#see_note_intern").val(data.note_intern);
											$("#see_state").val(data.state);
											$('#see_note_client').val(data.note_client)

											if (data.service.slug == 'carrosserie') {
												$(".carrosserie").removeClass('visually-hidden')
											}
											else {
												$(".carrosserie").addClass('visually-hidden')
											}


											modal_appointment.show()
										})
									}
								})
							})
						})
					})
					// data.forEach(function(appointment) {
					// 	$("#list_appointments").append(`
					// 		<tr class='${appointment.service.slug}'>
					// 			<td class='appointment-${appointment.id} p-3' data-appointment_id='${appointment.id}' width='200'>
					// 				${appointment.time} à ${parseInt(appointment.time.replace('h:00','')) + appointment.service.duration}h:00
					// 			</td>
					// 			<td class='appointment-${appointment.id} p-3' data-appointment_id='${appointment.id}'>
					// 				${appointment.service.name} (${appointment.user.full_name})
					// 			</td>
					// 		<tr>
					// 	`)

					// 	$(`.appointment-${appointment.id}`).on("click", function(e) {
					// 		console.log(45445)
					// 		fetch(`/appointments/${$(e.currentTarget).data('appointment_id')}`, {
					// 			method: 'GET',
					// 			mode: 'cors',
					// 			cache: 'default',
					// 		}).then(function(response) {
					// 			if (response.ok) {
					// 				response.json().then(function(data) {
					// 					$(`.appointment-${$(e.currentTarget).data('appointment_id')} .indicator`).remove()
					// 					$('#see_appointment_id').val(data.id)
					// 					$("#see_service").val(`${data.service.name} (${data.service.duration} ${Pluralize( 'heure', data.service.duration )})`)
					// 					$("#see_date").val(moment(data.date).locale("fr").format('dddd DD MMM YYYY'))
					// 					$("#see_periode").val(`${data.time} à ${parseInt(data.time.replace('h:00','')) + data.service.duration}h:00`);
					// 					$("#see_full_name").val(data.user.full_name)
					// 					$("#see_phone").val(data.phone)
					// 					$("#see_email").val(data.email);
					// 					$("#see_car_number").val(`${data.car.model} (${data.car.car_number})`);
					// 					$("#see_km").val(data.km);
					// 					$("#see_notes").val(data.notes);
					// 					$("#see_description").val(data.description);
					// 					$("#see_note_intern").val(data.note_intern);
					// 					$("#see_state").val(data.state);
					// 					$('#see_note_client').val(data.note_client)

					// 					if (data.service.slug == 'carrosserie') {
					// 						$(".carrosserie").removeClass('visually-hidden')
					// 					}
					// 					else {
					// 						$(".carrosserie").addClass('visually-hidden')
					// 					}

					// 					modal_appointment.show()
					// 				})
					// 			}
					// 		})
					// 	})
					// })


					// const calendar = new FullCalendar.Calendar(document.getElementById("myCalendar"), {
					//     initialView: 'dayGridMonth',
					//     themeSystem: 'bootstrap',
					//     headerToolbar: { center: 'dayGridMonth,timeGridWeek' },
					//     dayMaxEventRows: true, // for all non-TimeGrid views
					//     views: {
					//       timeGrid: {
					//         dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
					//       }
					//     },
					// });
				})
			}
		})
	}

})(jQuery);

