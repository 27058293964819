document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "registrations" || $("body").data("controller") == "clients" || $("body").data("controller") == "sessions" ) {

        $('#btn-register').on("click", function(e) {
            e.preventDefault()
            
            if ($("#user_password").val() != $("#user_password_confirmation").val()) {

                $(".errors").html(`
                    <div id="error_explanation">
                        <div class="alert alert-danger text-sm-start ">
                            <ul>
                                <li>Password confirmation est incorrecte!</li>
                            </ul>
                        </div>
                    </div>
                `)
                
                return
            }

            $("#new_user").validate()
            $('#new_user').on("submit", function(e) {
                e.preventDefault()
            })

            

            if ($("#new_user").valid()) {
                document.getElementById("new_user").submit()
            }
        })
        
        $('#user_phone').mask('(216) 99 999999');

        $('#user_login_phone').mask('(216) 99 999999');
    }
})