document.addEventListener("turbolinks:load", function() {
    if ($("body").data("user_role") == "super_admin" || $("body").data("user_role") == "admin") {
        function getSolde() {
            fetch(`/settings/solde/`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        if (parseFloat(data.solde) != NaN) {
                            if ( parseFloat(data.solde) >= 50) {
                                $(".current_solde").text(`Solde SMS: ${data.solde} TND`)
                            }
                            else{
                                $(".current_solde").html(`Solde SMS: <span class='badge bg-danger' style='font-size: 12px;'>${data.solde} TND</span>`)
                            }

                        }
                        else {
                            $(".current_solde").text('-')
                        }
                    })
                }
            })
        }

        getSolde()

}

    if($("body").data("controller") == "clients" && ($("body").data("user_role") == "admin" || ($("body").data("user_role") == "super_admin") || $("body").data("user_role") == "manager") ) {

        let modal_car;
        let table_clients;
        let table_cars

        if (document.getElementById("modal-car") != null) {
            modal_car = new bootstrap.Modal(document.getElementById("modal-car"), {backdrop: 'static', keyboard: false});
        }
        
        document.addEventListener("turbolinks:before-cache", function() {
            if (table_clients != null)
                table_clients.destroy();
            if (table_cars != null)
                table_cars.destroy();
        })

        $('#user_phone').mask('(216) 99 999999');
        
        let car_id;

        table_cars = $('#table-cars').DataTable({
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-cars').data('source'),
                data: function(e) {
                    e.user_id = $("#client_id").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "car_number"},
                {"data": "model"},
                {"data": "id",
                    "render": function(data, display, record) {
                        let destroy = `<a href="javascript:void(0)" class="btn-destroy-car" data-car_id="${record.id}" title="Retirer" style="color:red"><i class='fa fa-cancel'></i></a>`
                        if (record.has_appointments == "true")
                            destroy = ""
                        return `<a href="javascript:void(0)" class="btn-edit-car" data-car_id="${record.id}" title="Voir"><button class="btn btn-sm btn-primary">Editer</button></a>
                                ${destroy}`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "initComplete":function( settings, json){

                $(document, ".btn-edit-car").unbind('click');

                $(document).on("click", ".btn-edit-car", function() {
                    car_id = $(this).attr("data-car_id")
                    $("#car-model").val($(`#table-cars tr[data-element_id='${car_id}'] td:nth-child(2)`).text())
                    $("#car-car_number").val($(`#table-cars tr[data-element_id='${car_id}'] td:nth-child(1)`).text())

                    modal_car.show()
                })

                $(document).on("click", ".btn-destroy-car", function() {
                    let car_id = $(this).attr("data-car_id")
                    destroy_car(car_id)
                })
            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": false,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": true,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[1,'desc']]
        });

        table_clients = $('#table-clients').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-clients').data('source'),
                data: function(e) {
                    //e.service_id = $("#filter-rate_services").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "full_name"},
                {"data": "email"},
                {"data": "phone"},
                {"data": "decorate_active"},
                {"data": "created_at", 
                    "render": function(data, display, record) {
                        return moment(new Date(record.created_at)).format("LLL")
                    }
                },
                {"data": "id",
                    "render": function(data, display, record) {
                        return `<a href="/clients/${record.id}" class='btn-see-rates' data-service_id='${record.id}'  title="Voir"><span class="material-symbols-outlined">visibility</span></a>`
                    }
                },
                {"data": "car"},
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
                if (document.querySelector(`#service_rating-${data.id}`) != null) {
                    
                   /* $(`#service_rating-${data.id}`).html("")
        
                    service_rater = null;
                    service_rater = rater({element: document.querySelector(`#service_rating-${data.id}`), rateCallback: function rateCallback(rating, done) {

                        service_rater.setRating(rating);
                        done();
                    }, 
                        starSize:32,
                        rating: parseInt(data.stars),
                    });

                    service_rater.disable()
                    $(`#service_rating-${data.id}`).html()*/
                }
            },
            "initComplete":function( settings, json){
               /* jQuery.each(json.data, function (i, val) {
                    service_rater = null;
                    service_rater = rater({element: document.querySelector(`#service_rating-${val.id}`), rateCallback: function rateCallback(rating, done) {

                        service_rater.setRating(rating);
                        done();
                    }, 
                        starSize:32,
                        rating: parseInt(val.stars),
                    });

                    service_rater.disable()
                })*/
            },
            "columnDefs": [
                {"visible": false, "targets": [6]}
            ],
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "pageLength": 10,
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bAutoWidth": false,
            'order' : [[4,'desc']]
        });
        
        $("#btn_add_car").on("click", function() {
            let nbre = $(".car_number").length
            $("#total_cars").val(nbre+1)
            $(".cars").append(`
                <div class='row mb-2'>
                    <div class='col-lg-3 col-md-3 col-6'>
                        <div class="form-floating">
                            <input type="hidden" name="user[car_id_${nbre+1}]" value="">
                            <input type='text' name='user[car_number_${nbre+1}]' data-id="" id='user-car_number_${nbre+1}' value='' class='form-control car_number needs-validation'>
                            <label>Matricule véhicule ${nbre+1}*</label>
                        </div>
                    </div>
                    <div class='col-lg-3 col-md-3 col-6'>
                        <div class="form-floating">
                            <input type="hidden" name="user[car_id_${nbre+1}]" >
                            <input type='text' name='user[car_model_${nbre+1}]' data-id="" id='user-car_model_${nbre+1}' class='form-control needs-validation'>
                            <label>Modèle véhicule ${nbre+1}*</label>
                        </div>
                    </div>
                    <div class='col mt-3 ps-0'>
                        <a href="javascript:(0)" class='btn-delete-car' data-car_id='' title="Supprimer"><i class="fa-solid fa-trash-can"></i></a>
                    </div>
                </div>
            `)

            $(".btn-delete-car").on("click", function() {
                let car_id = $(this).attr("data-car_id")
    
                if (car_id == "") {
                    $(this).parent().parent().remove()
                }
            })
        })

        $(".btn-active-car").on("click", function() {
            let car_id = $(this).attr("data-car_id")
            let is_active = $(this).attr("data-is_active")
            let title = "Activer?"
            if (is_active == 'true') {
                title = 'Désactiver?'
                new_state = false
            }
            else {
                new_state = true
            }

            Swal.fire({
                title: title,
                text: `Voulez-vous vraiment ${title.toLowerCase().replace('?', '')} ce véhicule?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                
                preConfirm: () => {
                    return fetch(`/clients/active-car/${car_id}/${new_state}`, {
                        method: 'PATCH',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                        if (response.ok) {
                            $(`.btn-active-car[data-car_id=${car_id}]`).attr("data-is_active", new_state)
                            if (new_state == false)
                                $(`input[data-id=${car_id}]`).css("background", "#ffe8e8")
                            else
                                $(`input[data-id=${car_id}]`).css("background", "white")

                            const toastLiveExample = document.getElementById('liveToast')
                            const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                            if (is_active == 'true')
                                $(".toast-body").text("Véhicule desactivé!")
                            else
                                $(".toast-body").text("Véhicule activé!")
                            

                            toastBootstrap.show()
                        }
                    })
                }
            }).then((result) => {})
            
        })

        $(".btn-delete-car").on("click", function() {
            let car_id = $(this).attr("data-car_id")

            if (car_id == "") {
                $(this).parent().parent().remove()
            }
            else {
                Swal.fire({
                    title: "Supprimer",
                    text: `Voulez-vous vraiment supprimer ce véhicule?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Annuler',
                    confirmButtonText: 'Oui',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showLoaderOnConfirm: true,
                    
                    preConfirm: () => {
                        return fetch(`/clients/destroy-car/${car_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {
                                $(`.car_${car_id}`).remove()

                                const toastLiveExample = document.getElementById('liveToast')
                                const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                                $(".toast-body").text("Véhicule supprimé!")
                                toastBootstrap.show()
                            }
                        })
                    }
                }).then((result) => {})
            }
        })

        $("#btn-new-car").on("click", function() {
            modal_car.show()
        })

        $("#btn-save-car").on('click', function(e) {
            if ($("#car-model").val().trim() != "" && $("#car-car_number").val().trim() != "") {
                let formData = new FormData();
                formData.append('model', $("#car-model").val());
                formData.append('car_number', $("#car-car_number").val());
                formData.append('user_id', $("#client_id").val());

                $("#btn-cancel-car").attr("disabled", true)
                $("#btn-save-car").attr("disabled", true)
                $("#btn-save-car .spinner-border ").removeClass("d-none")

                let url = "/profile/create-car"
                let method = "POST"

                if (car_id != null) {
                    url = `/profile/update-car/${car_id}`
                    method = "PUT"
                }

                fetch(`${url}`, {
                    method: method,
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {

                            table_cars.ajax.reload()
                            
                            $("#btn-cancel-car").attr("disabled", false)
                            $("#btn-save-car").attr("disabled", false)
                            $("#btn-save-car .spinner-border ").addClass("d-none")

                            const toastLiveExample = document.getElementById('liveToast')
                            const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                            $(".toast-body").text("Véhicule Ajouté!")
                            toastBootstrap.show()

                            //modal_car = new bootstrap.Modal(document.getElementById("modal-car"), {backdrop: 'static', keyboard: false});
                            modal_car.hide()
                        })
                    }
                })
            }
        })

        $("#btn-save-client").on("click", function() {
            $(".error-client").addClass("d-none")
            $(".alert-client").addClass("d-none")

            update_client()
        })

        if (document.getElementById("modal-car") != null) {
            document.getElementById("modal-car").addEventListener('hidden.bs.modal', function (event) {
                $("#car-car_number").val("")
                $("#car-model").val("")

                car_id = null
            })

        }
        
        function update_client() {
            if ($("#user-full_name").val().trim() != "" && $("#user-phone").val().trim() != "") {
                let formData = new FormData();
                formData.append('user[active]', $("#user-active").prop("checked"));
                formData.append('user[full_name]', $("#user-full_name").val());
                formData.append('user[email]', $("#user-email").val());
                formData.append('user[phone]', $("#user-phone").val());
                formData.append('user[id]', $("#client_id").val());

                $("#btn-save-client").attr("disabled", true)
                $("#btn-save-client .spinner-border ").removeClass("d-none")

                let url = "/profile"
                let method = "POST"

                fetch(`/profile`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'default',
                    body: formData,
                }).then(function(response) {
                    if (response.ok) {
                        response.json().then(function(data) {
                            $("#btn-save-client").attr("disabled", false)
                            $("#btn-save-client .spinner-border ").addClass("d-none")

                            $(".alert-client").removeClass("d-none")
                            $(".alert-client").text("Client mis à jour!")
                        })
                    }
                    else {
                        response.json().then(function(data) {
                            $("#btn-save-client").attr("disabled", false)
                            $("#btn-save-client .spinner-border ").addClass("d-none")

                            $(".error-client").removeClass("d-none")
                            $(".error-client").text(data.error.join(', '))

                        })
                    }
                })
            }
        }


        function destroy_car(car_id) {
            Swal.fire({
                title: 'Retirer?',
                text: "Voulez-vous vraiment retirer ce véhicule?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#a7a7a7',
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Oui',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return fetch(`/profile/destroy-car/${car_id}`, {
                        method: 'DELETE',
                        mode: 'cors',
                        cache: 'default',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(function(response) {
                       
                        table_cars.ajax.reload()

                        const toastLiveExample = document.getElementById('liveToast')
                            const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
                            $(".toast-body").text("Véhicule retiré!")
                            toastBootstrap.show()
                    })
                }
            }).then((result) => {})
        }
    }
})