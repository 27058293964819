
document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "home" && $("body").data("action") == "index" && ($("body").data("user_role") == "admin" || $("body").data("user_role") == "super_admin" || $("body").data("user_role") == "manager"))  {
        let table_smslog;

        document.addEventListener("turbolinks:before-cache", function() {
            if (table_smslog != null)
                table_smslog.destroy();
        })

        table_smslog = $('#table-smslog').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-smslog').data('source'),
                data: function(e) {
                    //e.user_id = $("#client_id").val()
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "user"},
                {"data": "phone"},
                {"data": "message_id"},
                {"data": "response"},
                {"data": "date"},
                {"data": "description"}
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "initComplete":function( settings, json){

            },
            language: {
                url: '/datatable-fr-FR.json',
            },
            "bPaginate": true,
            "bFilter": true,
            "bInfo": true,
            "bLengthChange": true,
            "searching": false,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "bAutoWidth": false,
            'order' : [[4,'desc']]
        });


        $('#chart_workshops_remaining_slots_date').datepicker({
            format: 'dd/mm/yyyy',
            multidate: false,
            multidateSeparator: ', ',
            autoclose: true,
            immediateUpdates: true,
            todayBtn: true,
            todayHighlight: true,
            toggleActive: false,
            updateViewDate: false,
            forceParse: false
        }).on("changeDate", function() {
            let date = moment(new Date($("#chart_workshops_remaining_slots_date").datepicker("getDate"))).format("YYYY-MM-DD")

            reservation(date)
        }).datepicker("setDate", "0")

        $("#reload_smslog").on("click", function() {
            JsLoadingOverlay.show({
                'overlayBackgroundColor': '#666666',
                'overlayOpacity': 0.6,
                'spinnerIcon': 'ball-atom',
                'spinnerColor': '#000',
                'spinnerSize': '2x',
                'overlayIDName': 'overlay',
                'spinnerIDName': 'spinner',
                'spinnerZIndex':99999,
                'overlayZIndex':99998,
                'containerID': "smslog_container"
              });

              fetch(`/acknowledgement`, {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                JsLoadingOverlay.hide()
                
                if (response.statusText != "No Content") {
                    if (response.ok) {
                        response.json().then(function(data) {
                            table_smslog.ajax.reload()

                            JsLoadingOverlay.hide()
                        })
                    }
                }
            })
        })

        function reservation(date) {
            JsLoadingOverlay.show({
                'overlayBackgroundColor': '#666666',
                'overlayOpacity': 0.6,
                'spinnerIcon': 'ball-atom',
                'spinnerColor': '#000',
                'spinnerSize': '2x',
                'overlayIDName': 'overlay',
                'spinnerIDName': 'spinner',
                'spinnerZIndex':99999,
                'overlayZIndex':99998,
                'containerID': "remaining_container"
              });
              

            if (date == null) {
                date = moment(new Date()).format("YYYY-MM-DD")
            }
            
            fetch(`/workshops/remaining_slots/${date}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
    
                        Highcharts.chart('chart_workshops_remaining_slots', {
                            chart: {
                                type: 'column'
                            },
                            title: {
                                text: 'Réservation',
                                align: 'left'
                            },
                            subtitle: {
                                text: '',
                                align: 'left'
                            },
                            xAxis: {
                                categories: data.services,
                                crosshair: true,
                                accessibility: {
                                    description: 'Réservation'
                                }
                            },
                            yAxis: {
                                min: 0,
                                title: {
                                    text: 'Nombre de réservation'
                                }
                            },
                            tooltip: {
                                valueSuffix: ' {series.name}'
                            },
                            plotOptions: {
                                column: {
                                    pointPadding: 0.2,
                                    borderWidth: 0
                                }
                            },
                            series: [
                                {
                                    name: 'Max',
                                    data: data.max_slots
                                },
                                {
                                    name: 'Réservé(s)',
                                    data: data.remains,
                                    color: 'yellow'
                                }
                            ]
                        });

                        JsLoadingOverlay.hide()
                    })
                }
            })
        }

        
        reservation()
    }
})